import React from "react";
import { Container, Divider, Grid } from "semantic-ui-react";

import LayoutInner from "../../components/layout/layout-inner";
import SEO from "../../components/seo/seo";
import SecondaryNavBar from "../../components/secondary-nav-bar/secondary-nav-bar";
import HeadingOverImage from "../../components/heading-over-image/heading-over-image";
import FeatureList from "../../components/feature-list/feature-list";
import colors from '../../components/colors';
import Quote from "../../components/quote/quote";
import BodyContent from "../../components/body-content/body-content";

const Page = () => {
  return (
    <LayoutInner microsite>
      <SEO title="How to get Rocksteady lessons for free" keywords={[`rocksteady`, `free`]} />

      <Container>
        <SecondaryNavBar
          product="primary"
          title="How to get rocksteady lessons for free"
          titlePath="/how-to-get-rocksteady-lessons-for-free/"
          titleActive={true}
          links={[]}
          cta="Please contact me to discuss Rocksteady lessons"
          ctaMobile="Please contact me"
          ctaLink="https://form.jotform.com/RocksteadyMusic/freeresources"
        />

        <HeadingOverImage
          text="Wellbeing boosting in-school rocksteady lessons at no cost to your school!"
          img="/images/how-to-get-rocksteady-lessons-for-free/bg_1.jpg"
          siteSection="primary"
        />

        <Divider hidden />

        <BodyContent>
          <p>
            Rocksteady is a band-based peripatetic music service delivered by professionals,
            which is <b>FREE to schools</b>. The inclusive programme is accessible to all pupils,
            supports wellbeing and contributes to academic progress. Join thousands of
            primary schools across the UK who benefit from Rocksteady lessons every week.
          </p>
        </BodyContent>

        <FeatureList
          siteSection="primary"
          features={[
            {
              title: "Free to schools",
              text:
                "Rocksteady is an affordable monthly subscription service, paid for by parents. "
            },
            {
              title: "Instruments included",
              text:
                "All we need is a space to teach lessons in, no storage space required."
            },
            {
              title: "Inclusive",
              text:
                "Lessons are suitable for all mainstream pupils regardless of experience. Bursary and Pupil Premium places are also available to ensure no one misses out."
            },
            {
              title: "Hassle free for schools",
              text:
                "We take care of all the timetables and parent communications, leaving you one less thing to worry about."
            },
            {
              title: "Boosts confidence and key life skills",
              text:
                "Rehearsals build towards a performance each term, which is a great way to build self-confidence, and learn teamwork and listening skills along the way."
            },
            {
              title: "Hassle free for parents",
              text:
                "There's a money back guarantee for the first month, and parents are free to cancel at any time. All instruments are provided."
            },
            {
              title: "Child led",
              text:
                "Rocksteady uses a unique mix of child led learning practices to ensure every child has a positive experience of music education."
            },
            {
              title: "Trusted",
              text:
                "Thousands of schools, parents and children across the UK enjoy Rocksteady lessons each week."
            }
          ]}
        />

        <BodyContent>
          <p style={{ fontSize: 18, textAlign: 'center' }}>
            Find out how easy it is to get started at your school, by registering your interest below.
          </p>
          <div
            style={{ display: "flex", justifyContent: "center", marginTop: 50 }}
          >
            <a
              href="https://form.jotform.com/RocksteadyMusic/freeresources"
              className="ui button"
              style={{
                backgroundColor: colors["primary"].cta,
                color: colors["primary"].ctaText,
                width: 500,
                borderRadius: 30,
                fontSize: "2em",
                lineHeight: "initial",
              }}
              target="_blank"
              rel="noopener noreferrer"
            >
              Click here to register <br />
              your interest for <br />
              Rocksteady lessons
            </a>
          </div>
          <Divider hidden />
          <Grid columns={1}>
            <Grid.Column>
              <Quote
                siteSection="primary"
                quote="Children absolutely love it, I can't think of any negatives to do with Rocksteady. It has been amazing!"
                quoteName="Caroline Wood, Head of School, Bedenham Primary, Gosport"
                reducedMargin
              />
            </Grid.Column>
          </Grid>
        </BodyContent>
      </Container>
    </LayoutInner>
  );
}

export default Page;
